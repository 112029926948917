$title-font-size: 1.5rem;
$playlist-height: 20rem;
$description-font-size: 1.1rem;

.playlist-container {
  border-left: 1px solid #434346;
  padding: 1rem 0;
  .playlist-title {
    font-size: $title-font-size;
    color: #f5f5f5;
    font-family: unica one;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  .empty-playlist {
    color: gray;
    text-align: center;
    font-size: $description-font-size;
  }
  .user-playlist-container {
    float: right;
    .user-playlist-label {
      // display: flex;
      cursor: pointer;
      .content {
        flex: 1;
        display: block;
      }
      .action-icon {
        margin: 0;
      }
    }
  }
  .playlist {
    height: $playlist-height * 1.2;
    overflow-y: auto;
    .song-item {
      display: flex;
      justify-content: space-between;
      color: whitesmoke;
      font-size: 1.4em;
      flex-shrink: 0;
      padding: .5rem 1rem;
      border-bottom: 1px solid #393939;
      cursor: pointer;
      .song-title {
        flex: 1;
        display: flex;
        padding-left: 1rem;
        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .download-link {
          display: flex;
          flex-direction: column;
          justify-content: center;
          a {
            color: whitesmoke !important;
          }
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
      &:hover {
        background-color: #3b3b4080;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    border-left: none;
    .playlist-title {
      font-size: $title-font-size * 1;
    }
    .empty-playlist {
      font-size: $description-font-size * 0.75;
    }
    .playlist {
      height: $playlist-height * 0.75;
      .song-item {
        .song-title {
          font-size: $description-font-size * 0.9;
        }
      }
    }
  }

  @media only screen and (min-width: 1500px) {
    .playlist-title {
      font-size: $title-font-size * 1.5;
    }
    .empty-playlist {
      font-size: $description-font-size * 1.5;
    }
    .playlist {
      height: $playlist-height * 1.5;
      .song-item {
        padding: 1.1rem 1.5rem;
        .song-title {
          font-size: $description-font-size * 1.3;
        }
      }
    }
  }

  @media only screen and (min-width: 1900px) {
    .playlist-title {
      font-size: $title-font-size * 2;
    }
    .empty-playlist {
      font-size: $description-font-size * 2;
    }
    .playlist {
      height: $playlist-height * 2;
      .song-item {
        padding: 1.2rem 1.6rem;
        .song-title {
          font-size: $description-font-size * 1.7;
        }
      }
    }
  }
}