@import './footer/footer';
@import '../../variables';

.desktop-layout {
  display: flex;
  flex-direction: column;
}
.mobile-layout {
  display: none;
}

@media only screen and (max-width: 768px) {
  .desktop-layout {
    display: none;
  }
  .mobile-layout {
    display: block;
  }
}

$header-padding: 0.5rem;

.layout-container {
  min-height: 100vh;
  .placeholder-menu {
    margin-top: 0;
    box-shadow: none;
    border: none;
  }
  .fixed-menu, .placeholder-menu {
    background-color: $menu-color;
    padding: $header-padding;
    .menu-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .menu-item {
    font-size: $font-size;
    color: whitesmoke !important;
    &:hover {
      color: orange !important;
    }
  }
  .logo {
    width: $logo-size;
    cursor: pointer;
  }
  .logo-name {
    width: $logo-size * 5;
    margin-left: 1rem;
    cursor: pointer;
  }
  &.mobile-layout {
    .pushable.menu {
      border: none;
      border-radius: 0;
      .pusher {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: $body-color;
      }
    }
  }

  @media only screen and (min-width: 1500px) {
    .fixed-menu {
      padding: $header-padding;
    }
    .logo {
      width: $logo-size * 1.5;
      cursor: pointer;
    }
    .logo-name {
      width: $logo-size * 5 * 1.5;
      cursor: pointer;
    }
    .menu-item {
      font-size: 1.5 * $font-size;
    }
  }

  @media only screen and (min-width: 1900px) {
    .fixed-menu {
      padding: $header-padding;
    }
    .logo {
      width: $logo-size * 2;
      cursor: pointer;
    }
    .logo-name {
      width: $logo-size * 5 * 2;
      cursor: pointer;
    }
    .menu-item {
      font-size: 2 * $font-size;
    }
  }
}