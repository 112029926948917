$title-font-size: 1rem;
.profile-menu {
  color: #d9a86c;
  font-size: 2rem !important;
  @media only screen and (max-width: 768px) {
    font-size: 2rem !important;
  }

  @media only screen and (min-width: 1500px) {
    font-size: 2.5rem !important;
  }

  @media only screen and (min-width: 1900px) {
    font-size: 3rem !important;
  }
}

.ui.dropdown.active.visible {
  .profile-menu-dropdown {
    background-color: #212121 !important;
    .item {
      color: #d9a86c !important;
      @media only screen and (max-width: 768px) {
        font-size: 1rem !important;
      }
    
      @media only screen and (min-width: 1500px) {
        font-size: 1.5rem !important;
      }
    
      @media only screen and (min-width: 1900px) {
        font-size: 2rem !important;
      }
    }
  }
}

.login-container {
  background-color: #212121 !important;
  color: #d9a86c;
  .login-title {
    font-size: $title-font-size;
    text-align: center;
  }
  .login-email {
    margin: 2rem 0 1rem;
  }
  .email-input-error {
    color: #d93025;
  }
  .email-input-success {
    color: rgb(81, 168, 30);
  }
  .send-otp-container {
    text-align: center;
    margin-top: 2rem;
    .send-otp {
      background-color: #d9a86c;
    }
    .verify-otp {
      margin-top: 1rem;
    }
  }
  @media only screen and (max-width: 768px) {
    .login-title {
      font-size: $title-font-size;
    }
    .login-email, .email-input-error, .email-input-success, .send-otp {
      font-size: $title-font-size;
    }
  }

  @media only screen and (min-width: 1500px) {
    .login-title {
      font-size: 1.5 * $title-font-size;
    }
    .login-email, .email-input-error, .email-input-success, .send-otp {
      font-size: 1.25 * $title-font-size;
    }
  }

  @media only screen and (min-width: 1900px) {
    .login-title {
      font-size: 2 * $title-font-size;
    }
    .login-email, .email-input-error, .email-input-success, .send-otp {
      font-size: 2 * $title-font-size;
    }
  }
}