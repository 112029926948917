@import './search';
@import './filter';
@import './playlist';
@import './audioTrack';

.music-player-container {
  border-radius: 30px;
  background: #2c2c2e;
  box-shadow:  29px 29px 31px #181819bf,
              -29px -29px 31px #404043bf;
  padding: 2rem;
  margin-bottom: 3rem;
}

@keyframes backPos {
  0% { 
    transform: rotate(-24deg); 
  } 
 
  100% {
    transform:rotate(0deg);  
  }
}

@keyframes vinyl {
  0% { 
    transform: rotate(0deg); 
  } 
  
  100% {
    transform:rotate(-24deg); 
  }
}

@keyframes animate { 
  0% { 
    transform: rotate(0deg); 
  } 

  100% { 
    transform: rotate(359deg); 
  }
}

$stick-size: 90px;
$disk-size: 200px;

.player-machine {
  position: relative;
  margin-top: 2rem;
  .vinylStick {
    position: absolute;
    width: $stick-size;
    left: 25%;
    transform-origin: 100% 0 ;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 1;
    &.backwords {
      animation: backPos 1.5s backwards;
    }
    &.forwards {
      animation: vinyl 1.5s forwards;
    }
  }
  .vinayl-disc {
    display: inline-block;
    position: relative;
    top: 50%;
    left: 35%;
    img {
      height: $disk-size;
    }
    &.playing {
      animation: animate 3s linear infinite;
    }
  } 
}

@media only screen and (max-width: 768px) {
  .player-machine {
    .vinylStick {
      width: $stick-size * 0.7;
      left: 0%;
      top: 5%;
    }
    .vinayl-disc {
      left: 10%;
      img {
        height: $disk-size * 0.9;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  .player-machine {
    .vinylStick {
      width: $stick-size * 1;
    }
    .vinayl-disc {
      img {
        height: $disk-size * 1.3;
      }
    }
  }
}

@media only screen and (min-width: 1900px) {
  .player-machine {
    .vinylStick {
      width: $stick-size * 1.3;
    }
    .vinayl-disc {
      img {
        height: $disk-size * 1.5;
      }
    }
  }
}