.filter-container {
  .desktop-version {
    display: block;
  }
  .mobile-version {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    .desktop-version {
      display: none;
    }
    .mobile-version {
      display: block;
    }
  }
}

.filter-container {
  .desktop-version {
    $font-size: 1.2rem;
    .filter-component {
      background-color: #2c2c2e;
      box-shadow: 10px 10px 42px #212121, -10px -10px 42px #212121;
      padding: 1rem;
      border-radius: 25px;
      color: #d9a86c;
      .filter-name {
        cursor: pointer;
        font-size: $font-size;
        @media only screen and (max-width: 768px) {
          font-size: $font-size * 0.66;
        }
  
        @media only screen and (min-width: 1500px) {
          font-size: $font-size * 1.5;
        }
  
        @media only screen and (min-width: 1900px) {
          font-size: $font-size * 2;
        }
      }
    }
  }
}

.filter-container {
  .mobile-version {
    background-color: #2c2c2e;
    box-shadow: 10px 10px 42px #212121, -10px -10px 42px #212121;
    padding: 1rem;
    border-radius: 25px;
    color: #d9a86c;
    .filter-component-title {
      font-size: 1rem;
      margin-left: 1rem;
    }
  }
}

.music-filter-option {
  padding: 0.5rem;
  @media only screen and (min-width: 1500px) {
    padding: 0.75rem;
  }
  @media only screen and (min-width: 1900px) {
    padding: 1rem;
  }
  .checkbox {
    label {
      font-size: $font-size;
      @media only screen and (max-width: 768px) {
        font-size: $font-size * 0.66;
      }

      @media only screen and (min-width: 1500px) {
        font-size: $font-size * 1;
      }

      @media only screen and (min-width: 1900px) {
        font-size: $font-size * 1.5;
      }
      color: white !important;
      &:hover {
        color: #d9a86c !important;
      }
    }
    &.ui.checked {
      label {
        color: #d9a86c !important;
      }
    }
  }
}