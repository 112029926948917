@import './components/index';
@import './variables';
@import './modules/home/styles/index';
@import './modules/subscribe/styles/index';
@import './modules/explore/styles/index';
@import './modules/sync/styles/index';
@import './modules/auth/styles/index';

body {
  margin: 0;
  font-family: "Abel", Helvetica, Arial, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-color;
  line-height: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.abel-font {
  font-family: "Abel", Helvetica, Arial, serif !important;
}

.unica-one-font {
  font-family: "Unica One" !important;
}

.text-center {
  text-align: center !important;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
