
$parent-margin: 3rem;
$title-font-size: 3rem;
$content-font-size: 1.5rem;
$image-size: 8rem;
$subscribe-item-font-size: 1.5rem;
$subscribe-email-font-size: 2rem;

.subscribe-container {
  margin: $parent-margin;
  .title-container {
    border-bottom: 2px solid $golden-color;
    width: 30%;
    margin: auto;
    .title {
      font-size: $title-font-size;
      color: white;
      line-height: 1;
      margin-bottom: 1rem;
    }
  }
  .content {
    font-size: $content-font-size;
    justify-content: space-evenly;
    color: whitesmoke;
    margin-top: 1rem;
    line-height: 1.5;
    text-align: justify;
  }
  .subscribe-item-container {
    margin-top: 2rem;
    .item-image-container {
      background: #2C2C2E;
      box-shadow: 25px 25px 50px #202022, -12px -12px 24px #38383A;
      border-radius: 50%;
      height: $image-size;
      width: $image-size;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      margin-bottom: 2rem;
      .item-image {
        height: 50%;
      }
    }
    .horizontal-rule {
      border: 1px solid #393939;
      box-shadow: 3px 3px 6px #151516, -3px -3px 6px #434346;
      width: 10rem;
    }
    .item-content {
      color: #797979;
      font-size: $subscribe-item-font-size;
      margin-top: 1rem;
    }
  }
  .subscribe-app-container {
    background: $body-color;
    box-shadow: 20px 20px 35px rgba(24, 24, 25, 0.75), -20px -20px 35px rgba(64, 64, 67, 0.75);
    padding: 2rem;
    margin-top: 4rem;
    border-radius: 2rem;
    .title {
      color: #797979;
      font-size: $content-font-size;
    }
    .email-container {
      border: 2px solid $golden-color;
      padding: 2rem;
      border-radius: 5rem;
      width: 50%;
      margin: auto;
      margin-top: 2rem;
      font-size: $subscribe-email-font-size;
      text-align: center;
      .emailTo {
        color: $golden-color;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .title-container {
      width: 70%;
      .title {
        font-size: 2rem;
      }
    }
    .subscribe-app-container {
      .email-container {
        width: 100%;
        font-size: 1rem;
        padding: 1rem;
      }
    }
  }

  @media only screen and (min-width: 1500px) {
    & {
      margin: $parent-margin * 1.5;
    }
    .title-container {
      .title {
        font-size: $title-font-size * 1.5;
        margin-bottom: 1.5rem;
      }
    }
    .content  {
      font-size: $content-font-size * 1.5;
      margin-top: 1.5rem;
    }
    .subscribe-item-container {
      margin-top: 3rem;
      .item-image-container {
        height: $image-size * 1.5;
        width: $image-size * 1.5;
      }
      .horizontal-rule {
        width: 15rem;
      }
      .item-content {
        font-size: $subscribe-item-font-size * 1.5;
        margin-top: 1.5rem;
      }
    }
    .subscribe-app-container {
      padding: 3rem;
      margin-top: 5rem;
      .title {
        font-size: $content-font-size * 1.5;
      }
      .email-container {
        padding: 3rem;
        font-size: $subscribe-email-font-size * 1.5;
        margin-top: 3rem;
      }
    }
  }

  @media only screen and (min-width: 1900px) {
    & {
      margin: $parent-margin * 2;
    }
    .title-container {
      .title {
        font-size: $title-font-size * 2;
        margin-bottom: 2rem;
      }
    }
    .content  {
      font-size: $content-font-size * 2;
      margin-top: 2rem;
    }
    .subscribe-item-container {
      margin-top: 4rem;
      .item-image-container {
        height: $image-size * 2;
        width: $image-size * 2;
      }
      .horizontal-rule {
        width: 20rem;
      }
      .item-content {
        font-size: $subscribe-item-font-size * 2;
        margin-top: 2rem;
      }
    }
    .subscribe-app-container {
      padding: 4rem;
      margin-top: 6rem;
      .title {
        font-size: $content-font-size * 2;
      }
      .email-container {
        padding: 4rem;
        font-size: $subscribe-email-font-size * 2;
        margin-top: 4rem;
      }
    }
  }
}