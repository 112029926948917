.search_container {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
  .search-input {
    .icon {
      @media only screen and (max-width: 768px) {
        font-size: 0.75rem;
      }

      @media only screen and (min-width: 1500px) {
        font-size: 2rem;
      }

      @media only screen and (min-width: 1900px) {
        font-size: 2.5rem;
      }
    }
    input {
      $font-size: 1.25rem;
      &, &:focus, &:visited, &:active {
        border-radius: 25px;
        background-color: rgba(25,25,25,.8);
        color: hsla(0,0%,100%,.4) !important;
        outline: none;
        border: none;
        box-shadow: 15px 15px 25px #181819bf, -15px -15px 25px #404043bf;
        font-size: $font-size;
        line-height: 1;
        border-bottom: 1px solid hsla(0,0%,100%,.4);
        border-right: 1px solid hsla(0,0%,100%,.4);

        @media only screen and (max-width: 768px) {
          font-size: $font-size * 0.66;
        }

        @media only screen and (min-width: 1500px) {
          font-size: $font-size * 1.5;
        }

        @media only screen and (min-width: 1900px) {
          font-size: $font-size * 2;
        }
      }
    }
  }
}