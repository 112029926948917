@import './player';

@keyframes radiate{
  0% {
    box-shadow: 0 0 0 5px rgba(217, 168, 108, 1), 0 0 0 25px rgba(217, 168, 108, 0.5), 0 0 0 rgba(217, 168, 108, 0)
  }
  100% {
     box-shadow: 0 0 0 50px rgba(217, 168, 108, 0), 0 0 0 0 rgba(217, 168, 108, 0), 0 0 0 25px rgba(217, 168, 108, 0.5)
  }
}

.world-map-container {
  margin-top: 1rem;
  position: relative;
  .region-circle {
    border: 1px solid #d9a86c;
    background-color: #d9a86c;
    height: 1rem;
    width: 1rem;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: orange;
      border: none;
      transform: scale(1.5);
      transition: all .3s;
      background: #d9a86c;
      animation: radiate 2s infinite;
    }
    &.visited {
      text-decoration: none;
      color: orange;
      border: none;
      transform: scale(1.5);
      transition: all .3s;
      background: #d9a86c;
      animation: radiate 2s infinite;
    }
  }
}

$region-name-font-size: 1rem;
.region-details-container {
  color: gray;
  .region-name {
    color: #d9a86c;
    text-transform: uppercase;
    font-family: unica one;
    font-size: 2 * $region-name-font-size;
    text-align: center;
  }
  .songs-count {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1.25 * $region-name-font-size;
  }
  .track-description {
    font-size: $region-name-font-size;
  }
}