$title-font-size: 1.2rem;
$track-time-font-size: 1rem;
$elevated-button-size: 50px;

.audio-track-container {
  .audio-track-title {
    font-size: $title-font-size;
    text-align: center;
    color: whitesmoke;
    margin-top: 2rem;
    .disabled-title {
      color: gray;
    }
  }
  .audio-track-time {
    text-align: right;
    color: whitesmoke;
    .disabled-time {
      color: gray;
    }
  }
  .audio-track-progress-bar-container {
    position: relative;
    margin-top: 1rem;
    .audio-track-progress-bar {
      .bar {
        background-color: #d9a86c !important;
      }
    }
    .audio-track-seeker {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  .audio-input {
    display: none;
  }
  .audio-controls-container {
    display: flex;
    width: 80%;
    margin: auto;
    .control-button {
      flex: 1;
      text-align: center;
    }
    .elevated-button {
      height: $elevated-button-size;
      width: $elevated-button-size;
      border-radius: 50%;
      background: #2c2c2e;
      box-shadow: -12px -12px 24px #38383a, 12px 12px 24px #202022;
      font-size: 15px;
      color: white;
      &:hover {
        box-shadow: -12px -12px 24px #202022, 12px 12px 24px #38383a;
        color: #d9a86c;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .audio-track-title {
      font-size: $title-font-size * 1;
      margin-bottom: 1rem;
    }
    .audio-track-time {
      font-size: $track-time-font-size * 0.8;
    }
    .audio-controls-container {
      .elevated-button {
        height: $elevated-button-size * 0.75;
        width: $elevated-button-size * 0.75;
      }
    }
  }

  @media only screen and (min-width: 1500px) {
    .audio-track-title {
      font-size: $title-font-size * 1.5;
    }
    .audio-track-time {
      font-size: $track-time-font-size * 1.3;
    }
    .audio-controls-container {
      .elevated-button {
        height: $elevated-button-size * 1.25;
        width: $elevated-button-size * 1.25;
      }
    }
  }

  @media only screen and (min-width: 1900px) {
    .audio-track-title {
      font-size: $title-font-size * 2.5;
    }
    .audio-track-time {
      font-size: $track-time-font-size * 1.7;
    }
    .audio-controls-container {
      .elevated-button {
        height: $elevated-button-size * 1.5;
        width: $elevated-button-size * 1.5;
      }
    }
  }
}

.audio-controls-volume-slider {
  input {
    padding: 0 !important;
  }
}