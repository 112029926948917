@import '../../../variables';
$image-size: 6rem;
$region-title-font-size: 1.75rem;
$country-margin: 1rem;

.explore-container {
  .title-container {
    border-bottom: 2px solid $golden-color;
    width: 30%;
    margin: auto;
    margin-top: 1rem;
    .title {
      font-size: $title-font-size;
      color: white;
      line-height: 1;
      margin-bottom: 1rem;
    }
  }
}

.explore-regions-container {
  background-color: $body-color;
  padding: 3rem;
  box-shadow: 31px 31px 41px rgba(24, 24, 25, 0.75), -31px -31px 41px rgba(64, 64, 67, 0.75);
  border-radius: 2rem;
  margin: 2rem;
  margin-top: 3rem;
  .region-column {
    display: flex;
    height: 100%;
    .divider {
      width: 3px;
      box-shadow: 3px 3px 6px #151516, -3px -3px 6px #434346;
      border: #393939;
    }
    .regions-container {
      flex: 1;
    }
  }
  .region-container {
    .item-image-container {
      background: #2C2C2E;
      box-shadow: 25px 25px 50px #202022, -12px -12px 24px #38383A;
      border-radius: 50%;
      height: $image-size;
      width: $image-size;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      margin-bottom: 1rem;
      .item-image {
        height: 50%;
        color: $golden-color;
      }
    }
    .title {
      color: $golden-color;
      font-size: $region-title-font-size;
      text-align: center;
      margin-bottom: 1rem;
    }
    .horizontal-rule {
      border: 1px solid #393939;
      box-shadow: 3px 3px 6px #151516, -3px -3px 6px #434346;
      width: 10rem;
    }
    .country-container {
      margin-bottom: 3rem;
      .country {
        color: white;
        font-size: $font-size * 1.15;
        text-align: center;
        margin: $country-margin;
      }
    }
  }
}

.explore-types-container {
  padding: 2rem;
  margin: 1rem 2rem;
  .types-container {
    background-color: $body-color;
    padding: 3rem;
    width: 80%;
    margin: auto;
    box-shadow: 10px 10px 41px #000, -10px -10px 41px #000;
    border-radius: 3rem;
    .type-item {
      display: flex;
      .image {
        cursor: pointer;
      }
      .name {
        font-size: 2rem;
        color: #797979;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1rem;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .explore-container {
    .title-container {
      width: 50%;
      .title {
        font-size: 3rem;
      }
    }
  }
  .explore-types-container {
    .types-container {
      width: 100%;
      padding: 1rem;
      .type-item {
        .image {
          width: 3rem;
        }
        .name {
          font-size: 1.5rem;
        }
      }
    }
  }
  .explore-regions-container {
    .region-column {
      .divider {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  .explore-container {
    .title-container {
      width: 30%;
      margin-top: 1.5rem;
      .title {
        font-size: $title-font-size * 1.5;
        margin-bottom: 1.5rem;
      }
    }
  }
  .explore-types-container {
    padding: 3rem;
    margin: 1.5rem 3rem;
    .types-container {
      padding: 4.5rem;
      width: 80%;
      .type-item {
        .image {
          width: 7rem;
        }
        .name {
          font-size: 3rem;
          padding-left: 1.5rem;
        }
      }
    }
  }
  .explore-regions-container {
    padding: 4.5rem;
    margin: 3rem;
    margin-top: 4.5rem;
    .region-container {
      .item-image-container {
        height: $image-size * 1.5;
        width: $image-size * 1.5;
        margin-bottom: 1.5rem;
      }
      .title {
        font-size: $region-title-font-size * 1.5;
        margin-bottom: 1.5rem;
      }
      .horizontal-rule {
        width: 15rem;
      }
      .country-container {
        margin-bottom: 4.5rem;
        .country {
          font-size: $font-size * 1.15 * 1.5;
          margin: $country-margin * 1.5;
        }
      }
    }
  }
}

@media only screen and (min-width: 1900px) {
  .explore-container {
    .title-container {
      width: 30%;
      margin-top: 2rem;
      .title {
        font-size: $title-font-size * 2;
        margin-bottom: 2rem;
      }
    }
  }
  .explore-types-container {
    padding: 4rem;
    margin: 2rem 4rem;
    .types-container {
      padding: 6rem;
      width: 80%;
      .type-item {
        .image {
          width: 10rem;
        }
        .name {
          font-size: 4rem;
          padding-left: 2rem;
        }
      }
    }
  }
  .explore-regions-container {
    padding: 6rem;
    margin: 4rem;
    margin-top: 6rem;
    .region-container {
      .item-image-container {
        height: $image-size * 2;
        width: $image-size * 2;
        margin-bottom: 2rem;
      }
      .title {
        font-size: $region-title-font-size * 2;
        margin-bottom: 2rem;
      }
      .horizontal-rule {
        width: 20rem;
      }
      .country-container {
        margin-bottom: 6rem;
        .country {
          font-size: $font-size * 1.15 * 2;
          margin: $country-margin * 2;
        }
      }
    }
  }
}