@import '../../../variables';

$footer-pading: 1rem;
.footer-container {
  background-color: $menu-color;
  color: $footer-color;
  font-size: $font-size;
  padding: $footer-pading;
  .contact-us-container {
    display: flex;
    .title {
      flex: 1;
    }
  }
  .mobile-data-container {
    display: flex;
    padding: 0.5rem;
    .mobile-item {
      flex: 1;
      text-align: center;
    }
  }
  @media only screen and (min-width: 1500px) {
    font-size: $font-size * 1.5;
    padding: $footer-pading * 1.5;
  }

  @media only screen and (min-width: 1900px) {
    font-size: $font-size * 2;
    padding: $footer-pading * 2;
  }
}

.desktop-footer {
  display: block;
}
.mobile-footer {
  display: none;
}

@media only screen and (max-width: 768px) {
  .desktop-footer {
    display: none;
  }
  .mobile-footer {
    display: block;
  }
}