@import '../../../variables';
$title-font-size: 1.5rem;

.sync-container {
  .title-container {
    .title {
      color: white;
      font-size: $title-font-size;
      padding: 2rem;
    }
    .horizontal-rule {
      border: 1px solid #393939;
      box-shadow: 3px 3px 6px #151516, -3px -3px 6px #434346;
    }
  }
  .sync-list {
    max-height: 70vh;
    overflow-y: auto;
    margin: 1rem;
    .sync-item-container {
      .sync-image {
        height: 20rem;
        border-radius: 1rem;
        margin: 1rem auto;
        width: 90%;
      }
    }
  }
  .sync-album-container {
    margin: 2rem;
    .album-image {
      margin: auto;
      width: 60%;
    }
    .title {
      color: $golden-color;
      font-size: $title-font-size * 2;
      margin-bottom: 1rem;
    }
    .horizontal-rule {
      border: 1px solid #393939;
      box-shadow: 3px 3px 6px #151516, -3px -3px 6px #434346;
      width: 50%;
    }
    .content {
      color: white;
      font-size: $title-font-size;
      div {
        margin: 1rem 0;
      }
    }
    .sync-player-container {
      margin-bottom: 4rem;
    }
    .songs-container {
      .song-item-container {
        display: flex;
        margin: 1rem 0;
        .song-title {
          display: flex;
          justify-content: center;
          flex-direction: column;
          color: white;
          font-size: $title-font-size;
          margin-left: 1rem;
        }
      }
    }
  }
  .achievements-container {
    .horizontal-rule {
      border: 1px solid #393939;
      box-shadow: 3px 3px 6px #151516, -3px -3px 6px #434346;
      width: 75%;
      margin: 2rem auto;
    }
    .title {
      font-size: 2rem;
      text-align: center;
    }
    .awards-data {
      display: flex;
      flex-wrap: wrap;
    }
    .award-conatiner {
      display: flex;
      margin: 1rem;
      min-width: 20rem;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
          margin: 0.25rem 0;
        }
        .award-title {
          color: $golden-color;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .sync-list {
    display: flex;
    flex-flow: row;
    .sync-item-container {
      width: 15rem;
      min-width: 15rem;
    }
  }
  .sync-album-container {
    .horizontal-rule {
      width: 100% !important;
    }
  }
}

@media only screen and (min-width: 1500px) {
  .sync-container {
    .title-container {
      .title {
        font-size: $title-font-size * 1.5;
        padding: 3rem;
      }
    }
    .sync-list {
      max-height: 70vh;
      margin: 1.5rem;
      .sync-item-container {
        .sync-image {
          height: 30rem;
          margin: 1.5rem auto;
        }
      }
    }
    .sync-album-container {
      margin: 3rem;
      .title {
        font-size: $title-font-size * 2 * 1.5;
        margin-bottom: 1.5rem;
      }
      .content {
        font-size: $title-font-size * 1.5;
        div {
          margin: 1.5rem 0;
        }
      }
      .songs-container {
        .song-item-container {
          margin: 1.5rem 0;
          .song-title {
            font-size: $title-font-size * 1.5;
            margin-left: 1.5rem;
          }
        }
      }
    }
    .achievements-container {
      .horizontal-rule {
        margin: 3rem auto;
      }
      .title {
        font-size: 3rem;
      }
      .award-conatiner {
        margin: 2rem;
        min-width: 25rem;
      }
    }
  }
}

@media only screen and (min-width: 1900px) {
  .sync-container {
    .title-container {
      .title {
        font-size: $title-font-size * 2;
        padding: 4rem;
      }
    }
    .sync-list {
      max-height: 70vh;
      margin: 2rem;
      .sync-item-container {
        .sync-image {
          height: 40rem;
          margin: 2rem auto;
        }
      }
    }
    .sync-album-container {
      margin: 4rem;
      .title {
        font-size: $title-font-size * 2 * 2;
        margin-bottom: 2rem;
      }
      .content {
        color: white;
        font-size: $title-font-size * 2;
        div {
          margin: 2rem 0;
        }
      }
      .songs-container {
        .song-item-container {
          margin: 2rem 0;
          .song-title {
            font-size: $title-font-size * 2;
            margin-left: 2rem;
          }
        }
      }
    }
    .achievements-container {
      .horizontal-rule {
        margin: 4rem auto;
      }
      .title {
        font-size: 4rem;
      }
      .award-conatiner {
        display: flex;
        margin: 3rem;
        min-width: 30rem;
        .content {
          div {
            margin: 0.25rem 0;
          }
        }
      }
    }
  }
}